<div class="main-body-con">
  <div class="page-container pt-4">
    <div class="page-hdr-outer ps-4 pe-4">
      <div class="page-hdr ">
        <h2>Carriers/Wholesaler Management</h2>
        <a href="javascript:void(0)" (click)="openAddCarrierModal()" title="">
          <span><img src="assets/images/plus.svg" alt="" /></span><label class="ps-2">Add Carrier/Wholesaler</label></a>
      </div>
      <div class="row" *ngIf="showError">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
            <ul class="view-error">
              <li *ngIf="unAthorizesd != ''">{{unAthorizesd}}</li>
              <ng-container *ngFor="let err of errorList">
                <li>{{err}}</li>
              </ng-container>
            </ul>
            <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
              aria-label="Close"></button>
          </div>
        </div>
      </div>
    </div>
    <!-- <img
          src="../../assets/images/trash.svg"
          alt="Deactivate" class="me-2" /> -->
    <div class="quick-ac flex-div" *ngIf="isAllChecked">
      <a href="javascript:void(0)" (click)="deleteCheckedCarriers('')" title="" class="flex-div ml-4">{{isInActive ?
        'Deactivate' :
        'Activate'}}</a>
    </div>
    <div class="grid-con">
      <div class="grid__wrapper wrp-pros">
        <kendo-grid filterable="menu" (filterChange)="filterChange($event)" [data]="FinalCarrierList" [skip]="skip" [virtualColumns]="true"
          [sortable]="true" [resizable]="true" [sort]="sort" (sortChange)="sortChange($event)" [filter]="gridFilter"
          [selectable]="{
            enabled: true,
            checkboxOnly: true,
            mode: 'multiple'
          }" [pageable]="{ pageSizes: true, position: 'top' }" [pageSize]="pageSize" [sortable]="{
            allowUnsort: false,
            mode: multiple ? 'multiple' : 'single'
          }" (pageChange)="carrierPaginate($event)">
          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <div class="flex-div justify-content-between grid-hd">
              <div class="flex-div">
                <kendo-textbox placeholder="Search in all columns..." #searchText
                  (input)="searchValue(searchText.value)" [style.width.px]="205"></kendo-textbox><img
                  src="assets/images/search.svg" (click)="onFilter()" alt="" class="ms-2" />
              </div>
              <div class="flex-div">
                <div class="flex-div">
                  <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                  <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                  <kendo-pager-next-buttons></kendo-pager-next-buttons>
                  <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
                  <kendo-grid-messages pagerItems="Items" [pagerItemsPerPage]="'Items per page'">
                  </kendo-grid-messages>
                </div>
                <kendo-pager-info class="bd-l"></kendo-pager-info>
              </div>
            </div>
          </ng-template>
          <kendo-grid-column [width]="10" field="Actions" [filterable]="false" [sortable]="false" class="overflow-visible">
            <ng-template kendoGridCellTemplate let-rowData>
              <a href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
              <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                <ng-container *ngIf="rowData.carrierStatus == 'Active'">
                  <li><a class="dropdown-item" href="javascript:void(0)" (click)="openEditCarrierModal(rowData)">
                      <img src="../../assets/images/edit2.svg" alt="Edit"><span>Edit</span></a></li>
                  <li><a class="dropdown-item" href="javascript:void(0)" (click)="deleteCarrierList(rowData)">
                      <img src="../../assets/images/trash.svg" alt="Delete"><span>Deactivate</span></a>
                  </li>
                </ng-container>
                <li *ngIf="rowData.carrierStatus == 'Inactive'"><a class="dropdown-item" href="javascript:void(0)"
                    (click)="deleteCarrierList(rowData)">
                    <img src="../../assets/images/edit.svg" alt="Activate"><span>Activate</span></a>
                </li>
              </ul>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-checkbox-column [width]="8">
            <ng-template kendoGridCellTemplate let-idx="rowIndex" let-rowData>
              <input (click)="changeSelection(rowData)" [kendoGridSelectionCheckbox]="idx" />
            </ng-template>
          </kendo-grid-checkbox-column>

          <kendo-grid-column field="carrierShortName" title="Name" [width]="30">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="cr-wd">
                <a href="javascript:void(0)" (click)="openEditCarrierModal(dataItem)" title=""
                  pTooltip="{{ dataItem.carrierShortName }}" tooltipPosition="bottom"
                  tooltipStyleClass="tooltipstyle">{{ dataItem.carrierShortName }}</a>
              </div>
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
                operator="startswith">
              </kendo-grid-string-filter-menu>
            </ng-template>
            <!-- <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="startswith">
                <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
              </kendo-grid-string-filter-cell>
            </ng-template> -->
          </kendo-grid-column>

          <kendo-grid-column field="submissionEmail" title="Submission Email" [width]="30">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
                operator="startswith">
              </kendo-grid-string-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="naic" title="Naic Code" [width]="15">
          </kendo-grid-column>
          <kendo-grid-column field="masterPackageTier" title="PKG Tier" [width]="15">
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
              let-filterService="filterService">
              <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFiltermasterPackageTier" [autoClose]="true"
                [data]="pkgTierList" [valuePrimitive]="true" (valueChange)="onmasterPackageTierChange($event)"
                textField="packageTierName" valueField="packageTierName">
              </kendo-multiselect>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="masterWorkersCompensationTier" title="WC Tier" [width]="15">
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
              let-filterService="filterService">
              <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFilterWCTier" [autoClose]="true"
                [data]="wcTierList" [valuePrimitive]="true"
                (valueChange)="onmasterWorkersCompensationTierChange($event)" textField="workersCompensationTierName"
                valueField="workersCompensationTierName">
              </kendo-multiselect>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="masterCarrierType" title="Carrier | Wholesaler" [width]="20">
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
              let-filterService="filterService">
              <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridmasterCarrierTypeList" [autoClose]="true"
                [data]="masterCarrierTypeList" [valuePrimitive]="true"
                (valueChange)="onmasterCarrierTypeChange($event)">
              </kendo-multiselect>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="carrierStatus" title="Status" [width]="10" filter="boolean">
            <kendo-grid-messages filterIsTrue="Active" filterIsFalse="Inactive">
            </kendo-grid-messages>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{
              dataItem.carrierStatus
              }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
    <p-dialog [header]="unAthorizesd != '' ? _confirmBox.header2 : _confirmBox.header" [modal]="true"
      [(visible)]="_confirmBox.isVisible" [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}"
      [draggable]="false" [resizable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
      <p *ngIf="unAthorizesd == ''">{{_confirmBox.message}}</p>
      <p *ngIf="unAthorizesd != ''">{{unAthorizesd}}</p>
      <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
        <p-button *ngIf="unAthorizesd == ''" (click)="_confirmBox.isVisible = false" label="No"
          styleClass="btn btn-outline-primary"></p-button>
        <p-button *ngIf="unAthorizesd != ''" (click)="_confirmBox.isVisible = false" label="OK"
          styleClass="btn btn-primary"></p-button>
        <p-button *ngIf="deleteFrom != 'fromList' && unAthorizesd == ''" (click)="deleteCarrierData()" label="Yes"
          styleClass="btn btn-primary">
        </p-button>
        <p-button *ngIf="deleteFrom == 'fromList' && unAthorizesd == ''" (click)="deleteCarrierData()" label="Yes"
          styleClass="btn btn-primary"></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>
<!-- Telrik end -->
