import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { CarrierListComponent } from './carrier-list/carrier-list.component';
import { DialCountComponent } from './dial-count/dial-count.component';


const routes: Routes = [

  { path: '', component: CarrierListComponent },
  { path: NavigationPath.carriermanagement, component: CarrierListComponent },
  { path: 'dial-count', component: DialCountComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CarrierManagementRoutingModule { }
