import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarrierManagementRoutingModule } from './carrier-management-routing.module';
import { CarrierListComponent } from './carrier-list/carrier-list.component';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { AddCarrierComponent } from './add-carrier/add-carrier.component';
import { DialogModule } from 'primeng/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { EditCarrierComponent } from './edit-carrier/edit-carrier.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpInterceptors } from 'src/app/shared/interceptors/http.interceptor';
import { CarriersService } from './services/carriers-service';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
import { DialCountComponent } from './dial-count/dial-count.component';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { CalendarModule } from 'primeng/calendar';
import { IconsModule } from '@progress/kendo-angular-icons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
@NgModule({
  declarations: [
    CarrierListComponent,
    AddCarrierComponent,
    EditCarrierComponent,
    DialCountComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CarrierManagementRoutingModule,
    PaginatorModule,
    ButtonModule,
    DialogModule,
    TooltipModule,
    SharedModule,
    DateInputsModule,
    CalendarModule,
    IconsModule,
    DropDownsModule
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpInterceptors,
    //   multi: true
    // },
    CarriersService,
  ],
})
export class CarrierManagementModule { }