<div class="main-body-con ">
    <div class="centered-div">
        <div class="page-container pt-4">
            <div class="page-hdr-outer ps-4 pe-4">
                <div class="page-hdr row flex-div">
                    <div class=" col-6 col-sm-6 col-md-6 col-lg-6">
                        <h2>Dial Count</h2>
                    </div>
                    <div class=" col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="  flex-div dial-count-cal">

                            <button class="btn btn-border createbt bck me-3"
                                (click)="dailCountDateChange(dailDate.value,'')"><span><img src="assets/images/ar.png"
                                        alt="" class="plus-icon" /></span></button>


                            <p-calendar class="form-control p-0" #dailDate [showIcon]="true" [iconDisplay]="'input'" [(ngModel)]="currentDate"
                                [maxDate]="todayDate" (onSelect)="changeDate(currentDate)" placeholder="MM/DD/YYYY"
                                dateFormat="mm/dd/yy">
                            </p-calendar>


                            <button class="btn btn-border createbt fwd ms-3" [ngClass]="{
                            img_icon: disabled
                          }" [disabled]="disabled" (click)="dailCountDateChange(dailDate.value,'Next')"><span><img
                                        [ngStyle]="{ opacity: disabled ? '0.123' : '' }" src="assets/images/ar.png"
                                        alt="" class="plus-icon" /></span></button>

                        </div>



                    </div>
                </div>
            </div>
        </div>
        <div class="grid-con mx-4 ">
            <div class="grid__wrapper wrp-pros custom-height1" id="noaction">
                <kendo-grid [resizable]="true" [data]="totalDialCount" filterable="menu" [skip]="skip" [virtualColumns]="true"
                    (filterChange)="filterChange($event)" [filter]="gridFilter"
                    [pageable]="{ pageSizes: true, position: 'top'}" [pageSize]="pageSize"
                    (pageChange)="dailPaginate($event)">
                    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                        <div class="flex-div justify-content-between grid-hd">
                            <div class="flex-div">
                            </div>
                            <div class="flex-div ">
                                <div class="flex-div">
                                    <div class="flex-div">
                                        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                                        <kendo-pager-numeric-buttons
                                            [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                                        <kendo-pager-next-buttons></kendo-pager-next-buttons>

                                        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
                                        <kendo-grid-messages pagerItems="Items" [pagerItemsPerPage]="'Items per page'">
                                        </kendo-grid-messages>
                                    </div>
                                    <kendo-pager-info class=" bd-l"></kendo-pager-info>

                                </div>

                            </div>
                        </div>
                    </ng-template>
                    <kendo-grid-column [minResizableWidth]="150" field="displayName" title="User Name">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- <kendo-grid-column [minResizableWidth]="150" field="roleName" title="Role">
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="rolesList" (valueChange)="onRoleNameChange($event,filterService)"
                                textField="roleName" valueField="roleName" [currentFilter]="filter"
                                [filterService]="filterService" [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column> -->
                    <kendo-grid-column [minResizableWidth]="150" field="position" title="Position">
                    </kendo-grid-column>
                    <kendo-grid-column [minResizableWidth]="150" field="noOfDials" title="Dial Count" format="n0">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <number-range-filter [field]="column.field" [filter]="filter"
                                [filterService]="filterService" [format]="'n0'">
                            </number-range-filter>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>
