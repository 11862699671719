<div class="modal-content">
  <div class="modal-body">
    <div class="add-pro-outer">


      <div class="add-hdr">
        <div class="heading">
          <h2>Add Carrier/Wholesaler</h2>
        </div>
        <div class="add-act">
          <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
            aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
        </div>
      </div>
      <div class="row" *ngIf="showError">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
            <ul class="view-error">
              <li *ngFor="let err of errorList">{{err}}</li>
            </ul>
            <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
              aria-label="Close"></button>
          </div>
        </div>
      </div>

      <div class="add-content add-form">
        <div class="pdg-con">
          <form class="row g-3" [formGroup]="addCarrierForm">
            <div class="add-form ">
              <div class="add-fld">
                <div class="add-title ">
                  <h3>New Carrier/Wholesaler</h3>
                </div>
                <div class="row">
                  <div class="row frmrow">
                    <div class="col-12 col-sm-12 col-md-12  mb-1  has-validation">
                      <label class="form-label">Type <span class='star-color'>*</span></label>
                      <select (change)="typeOfEvent($event)" class="select-cs form-select"
                        [class.errorInput]="f.Type.invalid && submitted" formControlName="Type">
                        <option value="">Select Type</option>
                        <option *ngFor="let type of typeList" [value]="type.id">{{type.masterCarrierTypeName}}
                        </option>
                      </select>
                      <div class="display-error" *ngIf="f.Type.invalid && submitted">
                        <div *ngIf="f.Type.invalid && submitted">
                          <span *ngIf="f.Type.errors?.required">
                            {{_addCarrierScreen.Type.required}}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12  mb-1  has-validation">
                      <label class="form-label">Name <span class='star-color'>*</span></label>
                      <input formControlName="carrierShortName" dataTextFocus
                        [class.errorInput]="f.carrierShortName.invalid && submitted" class="form-control"
                        placeholder="Name" type="text" (focusout)="removeSpaces($event, f.carrierShortName)" />
                      <div class="display-error" *ngIf="f.carrierShortName.invalid && submitted">
                        <div *ngIf="f.carrierShortName.invalid && submitted">
                          <span *ngIf="f.carrierShortName.errors?.required">
                            {{_addCarrierScreen.carrierShortName.required}}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12  mb-1  has-validation">
                      <label class="form-label">NAIC <span *ngIf="naicMandatoryFlag" class='star-color'>*</span></label>
                      <input formControlName="naicCode" [class.errorInput]="f.naicCode.invalid && submitted"
                        class="form-control" placeholder="NAIC" type="text"
                        (keypress)="ValidateKey.integer($event,5)" />
                      <div class="display-error" *ngIf="f.naicCode.invalid && submitted">
                        <div *ngIf="f.naicCode.invalid && submitted">
                          <span *ngIf="f.naicCode.errors?.required">
                            {{_addCarrierScreen.naicCode.required}}
                          </span>
                        </div>
                      </div>


                    </div>

                    <div class="col-12 col-sm-12 col-md-12  mb-1  has-validation">
                      <label class="form-label">Submission Email</label>
                      <input class="form-control" placeholder="example@email.com" type="email"
                        formControlName="submissionEmail" />
                      <div class="display-error" *ngIf="f.submissionEmail.invalid && submitted">
                        <span *ngIf="f.submissionEmail.errors?.pattern">
                          {{_addCarrierScreen.submissionEmail.pattern}}
                        </span>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12  mb-1  has-validation">
                      <label class="form-label">Parent/Carrier Group<span></span></label>
                      <input class="form-control" placeholder="Parent/Carrier Group" type="text"
                        formControlName="groupName" />
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-1">
                      <label class="form-label">PKG Tier <span class='star-color'></span></label>
                      <select class="select-cs form-select" placeholder="PKG Tier" formControlName="PKGTier">
                        <!-- [(ngModel)]="selectedPKGTier" -->
                        <option value="">Select PKG Tier</option>
                        <option *ngFor="let pkgTier of pkgTierList" [value]="pkgTier.id">{{pkgTier.packageTierName}}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 mb-1">
                      <label class="form-label">WC Tier <span class='star-color'></span></label>
                      <select class="select-cs form-select" placeholder="WC Tier" formControlName="WCTier">
                        <!--  [(ngModel)]="selectedWCTier" -->
                        <option value="">Select WC Tier</option>
                        <option *ngFor="let wcTier of wcTierList" [value]="wcTier.id">
                          {{wcTier.workersCompensationTierName}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- <h3>Contacts</h3> -->
        </div>
        <!-- <div class="table-responsive table-responsive-md list-prospect tble-hgt-contact tag-tbl" id="table-scroll">
          <table class="table my-3">
            <thead class="table-dark">
              <tr class="table-hd">
                <th>Full Name</th>
                <th>Email</th>
                <th>PKG Contact</th>
                <th>WC Contact</th>
              </tr>
              <tr>
                <td>Jeff Doe</td>
                <td>jeff@email.com</td>
                <td>Y</td>
                <td>Y</td>
              </tr>

            </thead>
          </table>
        </div> -->
      </div>
      <div class="blk-bt">
        <button class="btn btn-outline-primary ms-2 me-3" (click)="cancel()">Cancel</button>
        <button class="btn btn-primary" (click)="saveCarrier()">Save</button>
      </div>
    </div>
    <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
      [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
      [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
      <p>{{_confirmBox.message}}</p>
      <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
        <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary"></p-button>
        <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>