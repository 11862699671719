export class addCarrierScreen {
    carrierShortName = {
        required: 'Carrier Name is required.'
    }

    naicCode = {
        required: 'NAIC Code is required.'
    }
    Type = {
        required: 'Type is required.'
    }

    submissionEmail = {
        pattern: 'Invalid Email Address'
    }
}