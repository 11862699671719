import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RegxConstant } from 'src/app/shared/class/regx';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { CarriersService } from '../services/carriers-service';
import { addCarrierScreen } from '../error-messages/addCarrier-messages';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { TreeViewLookupService } from '@progress/kendo-angular-treeview/treeview-lookup.service';

@Component({
  selector: 'app-add-carrier',
  templateUrl: './add-carrier.component.html',
  styleUrls: ['./add-carrier.component.css'],
})
export class AddCarrierComponent implements OnInit, OnDestroy {
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  addCarrierForm;
  _regxConstant: RegxConstant;
  _addCarrierScreen: addCarrierScreen;
  selectedPKGTier: any;
  selectedWCTier: any;
  selectedType: any;
  submitted: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  wcTierList: any[] = [];
  wcTierList$!: Observable<any>;
  pkgTierList$!: Observable<any>;
  typeList$!: Observable<any>;
  saveCarrier$!: Observable<any>;
  pkgTierList: any[] = [];
  typeList: any[] = [];
  showError: boolean = false;
  _navigationPath: NavigationPath;
  unsubscribe$: Subject<boolean> = new Subject();
  @Output() messageEvent = new EventEmitter<string>();
  unAthorizesd: any = '';
  naicMandatoryFlag: boolean = true;
  constructor(
    public activeModal: NgbActiveModal,
    private _carriersService: CarriersService,
    private _loaderService: LoaderService,
    public ValidateKey: KeyboardValidation,
    private fb: UntypedFormBuilder,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    public profileInfoService: ProfileInfoService,
  ) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this._regxConstant = new RegxConstant();
    this._addCarrierScreen = new addCarrierScreen();
    this.addCarrierForm = this.fb.group({
      carrierName: [''],
      carrierShortName: ['', [Validators.required]],
      naicCode: ['', [Validators.required]],
      submissionEmail: ['', Validators.pattern(this._regxConstant.Email())],
      Type: ['', [Validators.required]],
      PKGTier: [''],
      WCTier: [''],
      groupName: ['']
    });
  }
  ngOnInit(): void {
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    this.getPKGTierList();
    this.getWCTierList();
    this.getTypeList();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
  getTypeList() {
    this._loaderService.show();
    this.typeList$ = this._carriersService.getTypeList();
    this.typeList$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (resp: any) => {
        if (resp) {
          this.typeList = resp.masterCarrierTypeList;
          this._loaderService.hide();
        }
      },
      (err: any) => {
        this.showError = true;
        this.errorMessage = err.message;
        this.errorList.push(this.errorMessage);
        this._loaderService.hide();
      }
    );
  }
  keyPressAlphaNumeric(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9 ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  getPKGTierList() {
    this._loaderService.show();
    this.pkgTierList$ = this._carriersService.getPKGTierList();
    this.pkgTierList$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (resp: any) => {
        if (resp) {
          this.pkgTierList = resp.packageTierList;
          this._loaderService.hide();
        }
      },
      (err: any) => {
        this.showError = true;
        this.errorMessage = err.message;
        this.errorList.push(this.errorMessage);
        this._loaderService.hide();
      }
    );
  }
  getWCTierList() {
    this._loaderService.show();
    this.wcTierList$ = this._carriersService.getWCTierList();
    this.wcTierList$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (resp: any) => {
        if (resp) {
          this.wcTierList = resp.workersCompensationTierList;
          this._loaderService.hide();
        }
      },
      (err: any) => {
        this.showError = true;
        this.errorMessage = err.message;
        this.errorList.push(this.errorMessage);
        this._loaderService.hide();
      }
    );
  }

  closeError() {
    this.showError = false;
  }

  saveCarrier() {
    this.submitted = true;
    if (!this.addCarrierForm.valid) {
      return;
    } else {
      let data = {
        carrierName: this.f.carrierName.value,
        carrierShortName: this.f.carrierShortName.value,
        naic: this.f.naicCode.value,
        masterCarrierTypeId: this.f.Type.value,
        masterPackageTierId: this.f.PKGTier.value ? this.f.PKGTier.value : null,
        masterWorkersCompensationTierId: this.f.WCTier.value ? this.f.WCTier.value : null,
        // masterCarrierTypeId: this.selectedType,
        // masterPackageTierId: this.selectedPKGTier,
        // masterWorkersCompensationTierId: this.selectedWCTier,
        submissionEmail: this.f.submissionEmail.value,
        groupName: this.f.groupName.value
      };
      this.saveCarrier$ = this._carriersService.createCarrier(data);
      this.saveCarrier$.pipe(takeUntil(this.unsubscribe$)).subscribe(
        (resp: any) => {
          if (resp) {
            this.closeModal();
            this.messageEvent.emit(resp);
            this.errorList = [];
          }
        },
        (err: any) => {
          this.errorList = [];
          if (err.status !== 200) {
            if (this.unAthorizesd) {
              this.showError = true;
              this.errorList.push(this.unAthorizesd);
              return
            }
            if (err.status === 400) {
              this.showError = true;
              let obj = err.error.errors;
              var arr = Object.keys(obj).map((key) => ({
                type: key,
                value: obj[key],
              }));
              this.errorMessage = arr[0].value[0];
              this.errorList.push(this.errorMessage);
            } else {
              this.showError = true;
              this.errorMessage = err.message;
              this.errorList.push(this.errorMessage);
            }
          }
        }
      );
    }
  }
  get f() {
    return this.addCarrierForm.controls;
  }
  cancel() {
    if (!this.addCarrierForm.dirty) {
      this.closeModal();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }
  showDialogBox() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(
      element.target.value,
      ''
    );
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }
  typeOfEvent(event: any) {
    let value1 = event.target.value;
    if (value1 == "2") {
      this.naicMandatoryFlag = false;
      this.addCarrierForm.get('naicCode')?.setValidators(null);;
      this.addCarrierForm.get('naicCode')?.updateValueAndValidity();
    }
    else {
      this.naicMandatoryFlag = true;
      this.addCarrierForm.get('naicCode')?.setValidators([Validators.required]);
      this.addCarrierForm.get('naicCode')?.updateValueAndValidity();
    }
  }
}