import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegxConstant } from 'src/app/shared/class/regx';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { CarriersService } from '../services/carriers-service';
import { addCarrierScreen } from '../error-messages/addCarrier-messages';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { AddContactComponent } from '../../contact/components/add-contact/add-contact.component';
import { EditContactComponent } from '../../contact/components/edit-contact/edit-contact.component';
import { DatePipe } from '@angular/common';
import { ContactService } from '../../contact/services/contact.service';

@Component({
  selector: 'app-edit-carrier',
  templateUrl: './edit-carrier.component.html',
  styleUrls: ['./edit-carrier.component.css']
})
export class EditCarrierComponent implements OnInit, OnDestroy {
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  addCarrierForm;
  _regxConstant: RegxConstant;
  _addCarrierScreen: addCarrierScreen;
  selectedPKGTier: any;
  selectedWCTier: any;
  selectedType: any;
  submitted: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  wcTierList: any[] = [];
  pkgTierList: any[] = [];
  typeList: any[] = [];
  showError: boolean = false;
  packageTierId: any;
  workersCompensationTierId: any;
  _navigationPath: NavigationPath;
  wcTierList$!: Observable<any>;
  pkgTierList$!: Observable<any>;
  typeList$!: Observable<any>;
  saveCarrier$!: Observable<any>;
  unsubscribe$: Subject<boolean> = new Subject();
  id: any;
  carrierContactInfoList: any = [];
  @Input() carrierData: any;
  @Output() messageEvent = new EventEmitter<string>();
  unAthorizesd: any = '';
  naicMandatoryFlag: boolean = true;
  showDeleteYesButton: boolean = false;
  carrierContactDeleteItem: any;

  constructor(public activeModal: NgbActiveModal,
    public datepipe: DatePipe,
    private modalService: NgbModal,
    private _carriersService: CarriersService,
    private _loaderService: LoaderService,
    public ValidateKey: KeyboardValidation,
    public profileInfoService: ProfileInfoService,
    private _contactService: ContactService,
    private fb: UntypedFormBuilder) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this._regxConstant = new RegxConstant();
    this._addCarrierScreen = new addCarrierScreen();

    this.addCarrierForm = this.fb.group({
      carrierName: [''],
      carrierShortName: ['', [Validators.required]],
      naicCode: ['', [Validators.required]],
      submissionEmail: ['', Validators.pattern(this._regxConstant.Email())],
      Type: ['', [Validators.required]],
      PKGTier: [''],
      WCTier: [''],
      groupName: [''],
      createdby: [''],
      createdOnDate: [''],
      lastModifiedDate: [''],
    });

  }
  ngOnInit(): void {
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    this.getPKGTierList();
    this.getWCTierList();
    this.getTypeList();
    if (this.carrierData.masterCarrierTypeId == "2") {
      this.naicMandatoryFlag = false;
      this.addCarrierForm.get('naicCode')?.setValidators(null);;
      this.addCarrierForm.get('naicCode')?.updateValueAndValidity();
    }
    this.selectedType = this.carrierData.masterCarrierTypeId;
    this.addCarrierForm.controls['carrierShortName'].patchValue(this.carrierData.carrierShortName);
    this.addCarrierForm.controls['naicCode'].patchValue(this.carrierData.naic);
    this.addCarrierForm.controls['submissionEmail'].patchValue(this.carrierData.submissionEmail);
    this.addCarrierForm.controls['groupName'].patchValue(this.carrierData.groupName);
    this.addCarrierForm.controls['createdby'].patchValue(this.carrierData.createdUserName);
    this.addCarrierForm.controls['createdOnDate'].patchValue(this.carrierData.createdDate ? new Date(this.carrierData.createdDate) : '');
    this.addCarrierForm.controls['lastModifiedDate'].patchValue(this.carrierData.lastModifiedDate ? new Date(this.carrierData.lastModifiedDate) : '');
    this.selectedPKGTier = this.carrierData.masterPackageTierId ? this.carrierData.masterPackageTierId : null;
    this.selectedWCTier = this.carrierData.masterWorkersCompensationTierId ? this.carrierData.masterWorkersCompensationTierId : null;
    this.id = this.carrierData.id;
    this.getCarrierContactList(this.id);
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
  getTypeList() {
    this._loaderService.show();
    this.typeList$ = this._carriersService.getTypeList();
    this.typeList$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
      if (resp) {
        this.typeList = resp.masterCarrierTypeList;
        this._loaderService.hide();
      }
    }, (err: any) => {
      this.showError = true;
      this.errorMessage = err.message;
      this.errorList.push(this.errorMessage);
      this._loaderService.hide();
    });
  }
  keyPressAlphaNumeric(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  getPKGTierList() {
    this._loaderService.show();
    this.pkgTierList$ = this._carriersService.getPKGTierList();
    this.pkgTierList$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
      if (resp) {
        this.pkgTierList = resp.packageTierList;
        this._loaderService.hide();
      }
    }, (err: any) => {
      this.showError = true;
      this.errorMessage = err.message;
      this.errorList.push(this.errorMessage);
      this._loaderService.hide();
    });
  }
  getWCTierList() {
    this._loaderService.show();
    this.wcTierList$ = this._carriersService.getWCTierList();
    this.wcTierList$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
      if (resp) {
        this.wcTierList = resp.workersCompensationTierList;
        this._loaderService.hide();
      }
    }, (err: any) => {
      this.showError = true;
      this.errorMessage = err.message;
      this.errorList.push(this.errorMessage);
      this._loaderService.hide();
    });
  }

  closeError() {
    this.showError = false;
  }

  saveCarrier() {

    this.submitted = true;
    if (!this.addCarrierForm.valid) {
      return
    } else {
      this._loaderService.show();
      let data = {
        carrierName: this.f.carrierName.value,
        carrierShortName: this.f.carrierShortName.value,
        naic: this.f.naicCode.value,
        id: this.id,
        masterCarrierTypeId: this.selectedType,
        masterPackageTierId: this.selectedPKGTier ? this.selectedPKGTier : null,
        masterWorkersCompensationTierId: this.selectedWCTier ? this.selectedWCTier : null,
        submissionEmail: this.f.submissionEmail.value,
        groupName: this.f.groupName.value
      };
      this.saveCarrier$ = this._carriersService.updateCarrier(data);
      this.saveCarrier$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
        if (resp) {
          this.closeModal();
          this.messageEvent.emit(resp);
          this._loaderService.hide();
        }
      }, (err: any) => {
        if (err.status !== 200) {
          this.errorList = [];
          if (this.unAthorizesd) {
            this.showError = true;
            this.errorList.push(this.unAthorizesd);
            return
          }
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
            this._loaderService.hide();
          } else {
            this._loaderService.hide();
            this.showError = true;
            this.errorMessage = err.message;
            this.errorList.push(this.errorMessage);

          }
        }
      });
    }
  }
  get f() {
    return this.addCarrierForm.controls;
  }
  cancel() {
    if (!this.addCarrierForm.dirty) {
      this.closeModal();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }

  // showDialogBox() {
  //   this._confirmBox.message = this._userMessages.cancelButtonMessage;
  //   this._confirmBox.isVisible = true;
  // }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

  getCarrierContactList(carrierId: any) {
    if (carrierId) {
      this._carriersService.carrierContactInfo(carrierId).subscribe(resp => {
        this.carrierContactInfoList = resp.carrierContactInfo;
      })
    }
  }

  typeOfEvent(event: any) {
    let value1 = event.target.value;
    if (value1 == "2") {
      this.naicMandatoryFlag = false;
      this.addCarrierForm.get('naicCode')?.setValidators(null);;
      this.addCarrierForm.get('naicCode')?.updateValueAndValidity();
    }
    else {
      this.naicMandatoryFlag = true;
      this.addCarrierForm.get('naicCode')?.setValidators([Validators.required]);
      this.addCarrierForm.get('naicCode')?.updateValueAndValidity();
    }
  }

  openContactModal() {
    const modalRef = this.modalService.open(AddContactComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    let data = {
      'carrierId': this.id,
      'contactType': 'Carrier',
      'carrierName': this.carrierData.carrierShortName
    }
    modalRef.componentInstance.fromContactList = data;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp.response == 'Add Contact from submission') {
        this.getCarrierContactList(this.id);
      }
    })
    modalRef.closed.subscribe((resp) => {

    });
  }

  openEditContactModel(item: any) {
    const modalRef = this.modalService.open(EditContactComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    let data = item;
    modalRef.componentInstance.fromContactList = item;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Edit Contact') {
        this.getCarrierContactList(this.id);
      }
    })
    modalRef.closed.subscribe((resp) => {
    });
  }

  openDeleteModel(item: any) {
    this.showDeleteYesButton = true;
    this.carrierContactDeleteItem = {
      contactId: item.id,
      prospectId: item.prospectId,
      carrierId: item.carrierId,
      isActive: item.isActive
    }
    this._confirmBox.message = this._userMessages.deleteButtonMessage;
    this._confirmBox.isVisible = true;
  }

  // Delete carrier contact
  delCarrierContact() {
    this._confirmBox.isVisible = false;
    this._contactService.inactivateContact(this.carrierContactDeleteItem.contactId, this.carrierContactDeleteItem.prospectId).subscribe(res => {
      if (res) {
        this.showError = false;
        this.errorList = [];
        this.showDeleteYesButton = false;
        this.getCarrierContactList(this.id);
      }
    }, (err: any) => {
      if (err.status !== 200) {
        this.errorList = [];
        if (this.unAthorizesd) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd);
          return
        }
        if (err.status === 400) {
          this.showError = true;
          let obj = err.error.errors;
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
          this.errorMessage = arr[0].value[0];
          this.errorList.push(this.errorMessage);
          this._loaderService.hide();
        } else {
          this._loaderService.hide();
          this.showError = true;
          this.errorMessage = err.message;
          this.errorList.push(this.errorMessage);

        }
      }
    })

  }

}